.App {
  text-align: center;
  margin: none;
  background-color: #282c34;
  min-height: 100vh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 10vmin);
  color: white;
  padding-top: 30px;
}

.location-search-input {
  width: 75vw;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 20px;
}

.search-google {
  width: 75vw;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 20px;
  margin: auto;
}

.destination.search-google {
  padding-top: 50px;
}

.map-container {
  height: 70vh;
}
.mapboxgl-canvas {
  left: 0;
}
/* .mapboxgl-canvas {
  top: 0;
  bottom: 0;
  width: 10vw;
  height: 10vh;
} */
